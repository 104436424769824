@import url("style/bootstrap-4.4.1/bootstrap.min.css");

html {
  font-size: 13px;
}

/****************************************************
* LAYOUT
***************************************************/
div.page {
  margin: 5px 8%;
}

div.footer {
  height: 30px;
  border-top: 2px solid gray;
  text-align: right;
  padding: 5px;
  font-size: 13px;
  font-weight: 400;
  vertical-align: middle;
}

div.header {
  vertical-align: middle;
  background-color: whitesmoke;
  border-bottom: 2px solid gray;
}

div.header table {
  height: 100%;
  width: 100%;
  border-spacing: 0;
}

td.header-logo {
  text-align: left;
  font-size: 70px;
  text-transform: uppercase;
}

td.header-actions {
  vertical-align: middle;
  font-size: 0.9em;
}

td.header-actions > div {
  text-align: right;
  padding-right: 5px;
}

div.page-content,
div.page-content div.tab-pane {
  padding: 10px;
}

.account-total {
  font-weight: bold;
  text-align: right;
  padding-top: 8px;
  font-size: 33px;
}

div.section-title {
  font-size: 1.5em;
  font-weight: bold;
  color: #666699;
  padding: 5px 0px;
  border-bottom: 1px solid #666699;
  margin-bottom: 10px;
}

div.sub-section-title {
  font-size: 1.3em !important;
  color: #666699;
  margin-bottom: 3px;
}

.accordion-title {
  font-size: 1.2em !important;
  color: #666699;
}

.description {
  color: #929292 !important;
}

.header-menu div.dropdown button {
  font-size: 15px !important;
}

/****************************************************
* DIALOGS AND WINDOWS
***************************************************/
.modal-header,
.modal-footer {
  background-color: #ddd;
}

/****************************************************
* FORMS
***************************************************/
.form-group.required .control-label:after {
  content: "*";
  color: red;
}

/****************************************************
* OVERRIDES
***************************************************/
.react-datepicker-wrapper {
  width: 100%;
}
.alert {
  padding: 5px;
  margin-bottom: 0;
}
.form-check-input {
  margin-top: 0;
}
.dropdown-item {
  font-size: 1.1em;
  padding: 5px 10px;
}
a.dropdown-item:hover {
  background-color: yellow;
  font-weight: bold;
}
label.control-label {
  font-weight: bold;
}
.table-borderless td {
  border: 0 !important;
}

/****************************************************
* GENERAL
***************************************************/

tr.updated-row {
  background-color: #caede9 !important;
  font-weight: bold;
}

td.label {
  font-size: 0.8em;
  text-align: right;
  padding-right: 10px;
  color: #a9a9a9;
  text-transform: capitalize;
  font-weight: 400;
}

tfoot.table-footer tr {
  font-size: 1.3em;
  border-top: 2px solid black;
}

tfoot.table-footer th {
  border: none;
  text-transform: uppercase;
}

tr.focus {
  background-color: #FFFAA0 !important;
  font-weight: bold;
}

.positive-amount {
  color: #28a745;
}

.negative-amount {
  color: #cc3333;
}

.amount-lg {
  font-weight: bold;
  text-align: right;
  font-size: 35px;
}

table.tbl-details td.label {
  width: 35%;
}

table.tbl-details td.label-no-width {
  font-size: 0.8em;
  text-align: right;
  padding-right: 10px;
  color: #a9a9a9;
  font-weight: 400;
}

div.error-message {
  margin-bottom: 10px;
}

div.flex-container {
  display: flex;
}

div.flex-child {
  flex: 1;
}

div.flex-child:first-child {
  margin-right: 5px;
}

/****************************************************
* USERS
***************************************************/
div.account-users {
  vertical-align: middle;
}

div.account-user {
  padding: 5px 0px;
  border-top: 1px solid #ddd;
}

div.account-user button {
  font-size: 1.1em;
}

div.account-user div {
  vertical-align: middle;
}

div.account-user-selected {
  background-color: #c0c0c0;
}

table.user-details > tbody > tr:first-child > td {
  border: none;
}

tr.row-selected {
  background-color: #fffacd !important;
}

/****************************************************
* NOTIFICATION
***************************************************/
div.confirm-message {
  font-style: italic;
  font-size: 1.1em;
  margin-bottom: 10px;
}

div.confirm-question {
  font-weight: bold;
  font-size: 1.1em;
  color: red;
}

/****************************************************
* FINANCIAL ACCOUNTS
***************************************************/
tr.fin-emergency {
  background-color: lightyellow;
}

tr.fin-disabled {
  background-color: lightgrey;
}

tr.fin-negative-bal td {
  color: red;
}

table.table-fin-accts td,
table.table-fin-accts th {
  vertical-align: middle;
}

div.fin-description {
  font-size: 1.3em;
}

div.fin-description div {
  font-size: 0.8em;
  margin-top: 5px;
  color: gray;
}

div.fin-descriptions {
  font-size: 0.9em;
  margin-top: 5px;
  color: gray;
}

table.designations th.updated {
  width: 135px;
}

table.designations th.amount {
  width: 150px;
}

table.designations td.amount {
  text-align: right;
  padding-right: 20px;
}

table.designations th.type {
  width: 150px;
}

table.stocks td.symbol {
  font-weight: bold;
}

table.stocks th.amount,
table.stocks td.amount {
  text-align: right;
}

table.stocks tr.disabled {
  background-color: lightgrey !important;
  font-style: italic;
}

table.emergencies td {
  font-size: 1.3em;
  text-align: right;
  vertical-align: top;
}

table.emergencies span.label {
  color: gray;
}

table.emergencies span.amount {
  font-size: 1.4em;
}

table.emergencies span.designated {
  font-weight: bold;
  font-size: 1.6em;
}

div.dropdown button {
  padding-top: 0.2rem !important;
  font-size: 14px !important;
}

table.tbl-emergencies td,
table.tbl-emergencies th {
  vertical-align: middle;
}

table.tbl-emergencies th.date {
  width: 115px;
}

table.tbl-emergencies th.action {
  width: 120px;
  text-align: center;
}

tr.tbl-row-disabled {
  background-color: lightgrey !important;
  font-style: italic;
}

table.tbl-emerg-desig th.date {
  width: 115px;
}

table.tbl-emerg-desig th.date {
  width: 115px;
}

table.tbl-emerg-desig .check {
  width: 100px;
  text-align: center;
}

table.tbl-emerg-desig .amount {
  text-align: right;
}

table.tbl-categories td,
table.tbl-categories th {
  vertical-align: middle;
}

div.cat-description {
  font-size: 1.2em;
}

div.cat-description div {
  font-size: 0.9em;
  margin-top: 5px;
  color: gray;
}

div.cat-description:first-child div {
  margin-top: 8px;
}

div.cat-actions {
  text-align: right;
}

div.cat-actions button {
  margin-left: 3px;
}

table.gzn-table td,
table.gzn-table th {
  vertical-align: middle;
}

table.gzn-table .gzn-col-date {
  width: 115px;
}

table.gzn-table .gzn-col-check {
  width: 100px;
  text-align: center;
}

table.gzn-table .gzn-col-amount {
  text-align: right;
}

span.gzn-detail-flag {
  font-weight: normal;
  font-size: 9px;
  font-style: italic;
  padding: 0px 5px;
  color: #0c5460;
  background-color: #d1ecf1;
  border: 1px solid#bee5eb;
  margin: 1rem;
}

.table-fixed thead {
  display: table;
  width: 100%;
  table-layout: fixed;
}
.table-fixed tbody {
  display: block;
  overflow-y: scroll;
}
.table-fixed tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

/****************************************************
* REPORTS
***************************************************/
div.report-page-title {
  display: block;
  font-size: 2.5em;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: 500;
  margin-top: 5px;
  margin-bottom: 10px;
}

div.budget-adjustment-report {
  margin-top: 5px;
}
div.budget-adjustment-report td.category {
  color: #003399;
}
div.budget-adjustment-report td.description {
  font-weight: 450;
}
div.budget-adjustment-report td.changes {
  width: 200px;
  text-align: right;
}
div.budget-adjustment-report div.no-changes {
  font-size: 0.9em;
  margin-bottom: 10px;
  color: gray;
}
div.budget-adjustment-report div.undesignated {
  font-size: 0.9em;
  margin-bottom: 10px;
}

div.stock-portfolio div.no-stocks {
  font-size: 0.85em;
  margin-bottom: 10px;
  color: gray;
}

/****************************************************
* TRANSACTIONS
***************************************************/
.table-transactions .col-date-amount {
  text-align: right;
  padding: 5px 10px !important;
  text-overflow: clip !important;
  font-size: 1.2em;
}
.table-transactions .amount {
  text-align: right;
  font-size: 1.2em;
  font-weight: bold;
}
.table-transactions .deposit-amount {
  color: rgb(25, 124, 225) !important;
}
.table-transactions .expense-amount {
  color: #cc3333 !important;
}
.table-transactions .transfer-amount {
  color: green !important;
}
.table-transactions .party {
  font-size: 1.3em;
  margin-bottom: 5px;
}
.table-transactions .to-from {
  font-weight: bold;
}
.table-transactions .reference {
  background-color: #f3f559;
  font-size: 0.8em;
  padding: 0px 5px;
  color: black !important;
}
.table-transactions .description {
  margin: 3px 0px !important;
}
button.btn-footer-toolbar {
  font-size: 1.2em;
  margin-right: 10px;
}
.table-transactions tr.tx-selected {
  background-color: #fffacd !important;
}

/****************************************************
* INCOME DISTRIBUTION
***************************************************/
.income-distribution div.header {
  font-size: 1.3em;
  font-weight: bold;
  color: #666699;
  padding: 5px 0px;
  border-bottom: 2px solid #666699;
  margin-bottom: 0px;
}
.income-distribution td.lbl {
  text-align: right;
  padding-right: 10px;
  color: #a9a9a9;
  text-transform: capitalize;
  font-weight: 400;
}
.income-distribution td.dtl {
  text-align: left;
}
.income-distribution div.section div.title {
  padding: 6px;
  font-size: 1.2em;
  background-color: rgb(232, 232, 232);
  text-transform: uppercase;
}
.income-distribution div.section div.item {
  padding: 6px;
  vertical-align: top;
  border-bottom: 1px dotted #ddd;
  margin-right: 0;
  margin-left: 0;
}
.income-distribution div.section div.item div {
  padding-right: 0;
  padding-left: 0;
}
.income-distribution tfoot {
  font-size: 1.2em;
  font-weight: bold;
  padding: 6px 0px;
  border-top: 2px solid #666699;
  text-align: right;
  text-transform: uppercase;
}
.income-distribution tfoot div.row {
  margin-right: 0;
  margin-left: 0;
}
.income-distribution tfoot div.row div {
  padding-right: 0;
  padding-left: 0;
}
.income-distribution table {
  border-spacing: 0;
  border-collapse: collapse;
}

/****************************************************
* TRANSFERS
***************************************************/
.transfer-info:first-of-type {
  margin-bottom: 25px;
}
.transfer-info div.title {
  padding: 6px 0px;
  background-color: rgb(232, 232, 232);
  text-transform: uppercase;
  font-weight: bold;
}
.transfer-info tfoot {
  font-size: 0.9em !important;
  padding: 6px 0px;
  border-top: 2px solid #666699;
  text-align: right;
  text-transform: capitalize;
}

/****************************************************
* SELECT
***************************************************/
div.select-option {
  padding: 5px 8px;
  border-bottom: 1px solid #ddd;
}
div.select-label {
  font-weight: bold;
  font-size: 1.1em;
}
div.select-descr {
  padding-top: 3px;
  color: #686868;
  font-size: 0.9em;
}
div.select-option-selected {
  background-color: #fffacd;
}
div.select-option-disabled {
  background-color: #f0f0f0;
}

/****************************************************
* FORM TABLE
***************************************************/

table.tbl-form td.field-label {
  text-align: right;
  vertical-align: middle;
  padding-right: 10px;
  width: 40%;
}

.unreconciled-amt {
  font-size: 3.5em;
}

.instruction {
  color: #929292 !important;
  font-size: 0.8em;
  font-style: italic;
}

/****************************************************
* RECONCILIATION
***************************************************/

table.tbl-reconciliations tr.reconciled {
  background-color: #e0e0e0;
}

table.tbl-reconciliations tr.changed {
  background-color: #f0fff0;
}

div.reconciliation-title {
  font-size: 2.5em;
  font-weight: bold;
  margin: 15px 0 3px 0;
}

table.tbl-prev-reconciliations td.statement-col {
  padding: 5px !important;
  text-align: right;
}

table.tbl-reconciliation-rewards td.label {
  vertical-align: middle;
  font-weight: bold;
}

.table-unstriped tbody tr:nth-of-type(odd) {
  background-color: transparent !important;
}

table.tbl-reconciliation-opt td.label {
  vertical-align: middle;
  text-align: right;
  padding-right: 10px;
  color: none;
}

table.tbl-reconciliation-opt tr {
  border: 0 !important;
}

table.tbl-reconciliation-opt {
  border-spacing: 0 !important;
}

/****************************************************
* DASHBOARD
***************************************************/

div.user-dashboard {
  box-sizing: border-box;
  display: block;
  text-align: left;
  white-space: normal;
  background: white;
  color: #474747;
  border-radius: 4px;
  border: 1px solid #d5d5d5;
  -webkit-box-shadow: 0 2px 3px rgba(0, 0, 0, 0.05);
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.05);
  overflow: visible !important;
  height: 100%;
  text-indent: initial;
  border-spacing: 2px;
  border-collapse: collapse;
}

.dashboard-big-amt {
  font-weight: bold;
  text-align: right;
  font-size: 30px;
  margin-bottom: 10px;
}

div.card-body div.card-text {
  margin-top: 5px;
}

div.user-dashboard h5.card-title {
  font-size: 23px;
}

table.dashboard-budget td {
  border: 0;
}

table.dashboard-income-expense thead th {
  text-align: center;
  background-color: rgb(247, 247, 247);
}
table.dashboard-income-expense td.label {
  padding-right: 5px;
  font-size: 0.8em !important;
}
table.dashboard-income-expense td.amount {
  font-weight: bold;
  font-size: 1.2em;
  text-align: right;
}
table.dashboard-income-expense td.income {
  color: rgb(74, 181, 235);
}
table.dashboard-income-expense td.expense {
  color: rgb(252, 104, 104);
}
table.dashboard-income-expense td.transactions {
  text-align: right;
}

table.dashboard-messages tbody {
  display: block;
  overflow-y: scroll;
}
table.dashboard-messages tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
  background-color: white;
}
table.dashboard-messages td.icon {
  width: 20px;
}
table.dashboard-messages tr {
  border: 0 !important;
}

/****************************************************
* SEARCH
***************************************************/

span.search-datum {
  margin-left: 8px;
  margin-right: 8px;
}

td.search-count {
  text-align: right;
}

.search-term {
  background-color: yellow;
  font-style: italic;
}
