@font-face {
  font-family: "GoogleSans-Product";
  src: url("style/google-sans/productsans-black-webfont.woff") format("woff"),
    url("style/google-sans/productsans-black-webfont.woff2") format("woff2"),
    url("style/google-sans/ProductSans-Black.ttf") format("truetype");
  font-weight: bolder;
  font-style: normal;
}
@font-face {
  font-family: "GoogleSans-Product";
  src: url("style/google-sans/productsans-blackitalic-webfont.woff")
      format("woff"),
    url("style/google-sans/productsans-blackitalic-webfont.woff2")
      format("woff2"),
    url("style/google-sans/ProductSans-BlackItalic.ttf") format("truetype");
  font-weight: bolder;
  font-style: italic;
}
@font-face {
  font-family: "GoogleSans-Product";
  src: url("style/google-sans/productsans-bold-webfont.woff") format("woff"),
    url("style/google-sans/productsans-bold-webfont.woff2") format("woff2"),
    url("style/google-sans/ProductSans-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "GoogleSans-Product";
  src: url("style/google-sans/productsans-bolditalic-webfont.woff")
      format("woff"),
    url("style/google-sans/productsans-bolditalic-webfont.woff2")
      format("woff2"),
    url("style/google-sans/ProductSans-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: "GoogleSans-Product";
  src: url("style/google-sans/productsans-italic-webfont.woff") format("woff"),
    url("style/google-sans/productsans-italic-webfont.woff2") format("woff2"),
    url("style/google-sans/ProductSans-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "GoogleSans-Product";
  src: url("style/google-sans/productsans-light-webfont.woff") format("woff"),
    url("style/google-sans/productsans-light-webfont.woff2") format("woff2"),
    url("style/google-sans/ProductSans-Light.ttf") format("truetype");
  font-weight: lighter;
  font-style: normal;
}
@font-face {
  font-family: "GoogleSans-Product";
  src: url("style/google-sans/productsans-lightitalic-webfont.woff")
      format("woff"),
    url("style/google-sans/productsans-lightitalic-webfont.woff2")
      format("woff2"),
    url("style/google-sans/ProductSans-LightItalic.ttf") format("truetype");
  font-weight: lighter;
  font-style: italic;
}
@font-face {
  font-family: "GoogleSans-Product";
  src: url("style/google-sans/productsans-medium-webfont.woff") format("woff"),
    url("style/google-sans/productsans-medium-webfont.woff2") format("woff2"),
    url("style/google-sans/ProductSans-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "GoogleSans-Product";
  src: url("style/google-sans/productsans-mediumitalic-webfont.woff")
      format("woff"),
    url("style/google-sans/productsans-mediumitalic-webfont.woff2")
      format("woff2"),
    url("style/google-sans/ProductSans-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: "GoogleSans-Product";
  src: url("style/google-sans/productsans-regular-webfont.woff") format("woff"),
    url("style/google-sans/productsans-regular-webfont.woff2") format("woff2"),
    url("style/google-sans/ProductSans-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "GoogleSans-Product";
  src: url("style/google-sans/productsans-thin-webfont.woff") format("woff"),
    url("style/google-sans/productsans-thin-webfont.woff2") format("woff2"),
    url("style/google-sans/ProductSans-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "GoogleSans-Product";
  src: url("style/google-sans/productsans-thinitalic-webfont.woff"),
    url("style/google-sans/productsans-thinitalic-webfont.woff2")
      format("woff2"),
    url("style/google-sans/ProductSans-ThinItalic.ttf") format("truetype")
      format("woff");
  font-weight: 100;
  font-style: italic;
}

html,
body {
  font-family: "GoogleSans-Product", sans-serif !important;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  display: block;
  line-height: 1 !important;
}
